import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled/macro'
import { DESKTOP_BREAKPOINT, Hidden, Typography, fdsTheme } from '@fazz/design-system'
import {
  Personal,
  AddWithBorder,
  ArrowDown,
  DeveloperTool,
  Settings,
  Logout,
} from '@fazz/design-system-icons'
import { useHistory } from '~/hooks/useReactRouter'
import isHasPermissionId from '~/biz/permission/isHasPermission'
import { PATHS } from '~/biz/routes/paths'
import { type RootState } from '~/biz/store/types'
import isHasPermissionSg from '~/bizRegional/permission/isHasPermission'
import { AppType, PERMISSIONS, UserRoles } from '~/types'
import { ELEVATIONS } from '~/common/elevation'
import { ConfigManager, isProduction } from '~/managers/ConfigManager'
import { MODE, ModeManager } from '~/managers/ModeManager'
import { appTypeMap } from '~/constants'
import SessionUtils from '~/utils/SessionUtils'
import StringUtils from '~/utils/StringUtils'

type UserProfileType = {
  isSandbox: boolean
  role?: UserRoles | null
}

type UserInfoProps = {
  isDropdownOpen: boolean
}

const { colors, spacing, radius, shadow } = fdsTheme
const { Label, Body } = Typography
const hideStringEllipsis = (str: string, limit: number) =>
  str?.length > limit ? `${str.slice(0, limit)}...` : str

export default function UserProfile({ isSandbox, role }: UserProfileType) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const history = useHistory()
  const {
    fullName,
    displayname: companyName,
    companyId,
  } = useSelector((state: RootState) => state.route.user)
  const dispatch = useDispatch()
  const containerRef = useRef<HTMLDivElement>(null)
  const registrationCountry = useSelector((state: RootState) => state.route.featureMatrix.country)
  const appType = appTypeMap[registrationCountry]
  const isHasPermissionAddAccount =
    appType === AppType.INDONESIA
      ? isHasPermissionId(PERMISSIONS.CREATE_NEW_MERCHANT)
      : isHasPermissionSg(PERMISSIONS.CREATE_NEW_MERCHANT)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Element)) {
      setIsDropdownOpen(false)
    }
  }
  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClick)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClick)
    }
  }, [dispatch])

  const goToMyLandingPage = () => {
    ModeManager.toggleMode()

    history.push(PATHS.LANDING_PAGE)
  }

  const goToNewBusinessAccountPage = () => {
    history.push(PATHS.REGISTER_MERCHANT)
  }

  // MyAccounts Page only exists in Production
  const goToMyAccountsPage = () => {
    ModeManager.toggleMode(MODE.PRODUCTION)

    history.push(PATHS.MANAGE_ACCOUNT)
  }

  /**
   * Different Menu for different Account type
   * Corporate Account (Everything except change mode )
   * Business Account (Everything)
   *
   * hiding companyname (ie user.displayname) for straitsX app users
   */
  return (
    <Container ref={containerRef}>
      <UserInfo
        data-cy="div-userInfo"
        onClick={() => setIsDropdownOpen((previousState) => !previousState)}
        isDropdownOpen={isDropdownOpen}
      >
        <>
          <Personal width={30} height={30} />
          <Hidden only="sm">
            <UserDetails>
              <UserDetails>
                <Label size="sm">{hideStringEllipsis(fullName, 20)}</Label>
                <Body size="sm" color={colors.onNeutralSecondary}>
                  {hideStringEllipsis(companyName || companyId, 20)}
                </Body>
              </UserDetails>
              <Body size="sm" color={colors.onNeutralSecondary}>
                {registrationCountry} • {role && role.length ? StringUtils.capitalize(role) : 'N/A'}
              </Body>
            </UserDetails>
          </Hidden>
        </>

        <Hidden only="sm">
          <SvgContainer>
            <ArrowDown width={20} height={20} style={{ placeSelf: `flex-end` }} />
          </SvgContainer>
        </Hidden>
      </UserInfo>
      {isDropdownOpen && (
        <UserMenu>
          {role !== UserRoles.FINANCE && (
            <ActionOptions onClick={goToMyLandingPage}>
              <IconContainer>
                <DeveloperTool data-cy="switch-sandbox" width={20} height={20} />
              </IconContainer>
              <Label size="md">
                Switch to{' '}
                {isSandbox ? StringUtils.capitalize(ConfigManager.getEnvironmentMode()) : 'Sandbox'}{' '}
                Mode
              </Label>
            </ActionOptions>
          )}

          <ActionOptions onClick={goToMyAccountsPage}>
            <IconContainer>
              <Settings width={20} height={20} />
            </IconContainer>
            <Label size="md">My Account</Label>
          </ActionOptions>
          {/* {
            // TODO: When Auth team finishes their API, refactor this to show all merchants - Kerk
            hasEmployers && employers.length > 1 && (
              <ActionOptions onClick={() => SessionUtils.ssoSwitchMerchantAccount('')}>
                <IconContainer>
                  <Employee width={20} height={20} />
                </IconContainer>
                <Label size="md">Switch Account</Label>
              </ActionOptions>
            )
          } */}
          {!isProduction && isHasPermissionAddAccount && (
            <ActionOptions onClick={goToNewBusinessAccountPage}>
              <IconContainer>
                <AddWithBorder width={20} height={20} />
              </IconContainer>
              <Label size="md">New Business Account</Label>
            </ActionOptions>
          )}

          <ActionOptions onClick={SessionUtils.ssoLogout}>
            <IconContainer>
              <Logout width={20} height={20} />
            </IconContainer>
            <Label size="md">Logout</Label>
          </ActionOptions>
        </UserMenu>
      )}
    </Container>
  )
}

const Container = styled.div`
  height: 100%;

  position: relative;
  display: flex;
  ${DESKTOP_BREAKPOINT} {
    width: 260px;
    justify-content: center;
    align-items: center;
  }
`

const IconContainer = styled.div`
  margin-right: ${spacing.md};
  color: ${colors.onNeutral};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ActionOptions = styled.div`
  height: 58px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${spacing.sm} ${spacing.md};
  border-left: 2px solid transparent;

  &:hover {
    transition: 0.3s ease-out;
    background: ${colors.selected};
    border-left: 2px solid ${colors.primary};
  }
`

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xxs};
  white-space: nowrap;
  overflow: hidden;
`

const UserInfo = styled.div<UserInfoProps>`
  height: 64px;
  display: flex;
  height: 100%;
  width: 100%;
  gap: ${spacing.md};
  align-items: center;
  cursor: pointer;
  padding: ${spacing.sm} ${spacing.md};

  background-color: ${({ isDropdownOpen }) => (isDropdownOpen ? colors.selected : 'inherit')};

  &:hover {
    transition: 0.3s ease-out;
    background-color: ${({ isDropdownOpen }) => (isDropdownOpen ? colors.selected : 'inherit')};
  }

  &:last-child {
    border-radius: 0;
  }
`

const SvgContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-content: flex-end;
`

const UserMenu = styled.div`
  position: absolute;
  top: 80px;
  right: 0px;
  z-index: ${ELEVATIONS.MENUS_AND_SUB_MENUS};
  width: 260px;
  background: ${colors.surfaceNeutral};
  box-shadow: ${shadow.action};
  border-radius: ${radius.lg};
  transition-timing-function: ease;
  overflow: hidden;
`
