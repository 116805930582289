import { ErrorBoundary, LEVEL_ERROR, useRollbarPerson } from '@rollbar/react'
import { Suspense, useEffect } from 'react'
import { clarity } from 'react-microsoft-clarity'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled/macro'
import { DESKTOP_BREAKPOINT, useModal, DashboardLayout } from '@fazz/design-system'
import { useHistory, useLocation, Route, Switch } from '~/hooks/useReactRouter'
import routes, { getCustomLayoutRoutes } from '~/bizRegional/routes/config'
import { getEmployers, init } from '~/bizRegional/store/route/actions'
import type { RootState } from '~/bizRegional/store/types'
import { fetchWallets } from '~/rootStore/wallets/actions'
import { useGetMaintenanceInfo } from '~/api/initialisation/initialisation.hooks'
import { AppType, UserAgent } from '~/types'
import ErrorPage from '~/components/ErrorPage'
import Footer from '~/components/Footer'
import MaintenancePage from '~/components/MaintenancePage'
import PageLoader from '~/components/PageLoader'
import { PrivateRoute, RouteHooks } from '~/components/Route'
import Sidebar from '~/components/Sidebar/Sidebar'
import UserInfoBar from '~/components/UserInfoBar'
import { getWebOrAppAgent, ConfigManager, isProduction } from '~/managers/ConfigManager'
import { ModeManager } from '~/managers/ModeManager'
import i18n, { Language } from '~/i18n'
import ClevertapUtils from '~/utils/ClevertapUtils'
import { getFeatureMatrixFactors } from './store/route/selectors'
import { RouteActions } from './store/route/types'
import { getTermLoanUserDetails } from './store/termLoan/actions'

type NavigationCommand = { instruction: string; origin: string; path: string }

export default function SGRoot() {
  const history = useHistory()
  const location = useLocation()
  const { resetModal } = useModal()
  const dispatch = useDispatch()
  const { isInitiated, user, isClevertapInitiated } = useSelector((state: RootState) => state.route)
  const { role, isAccountManagerVerified, isBizVerified } = useSelector(getFeatureMatrixFactors)
  const isSandbox = ModeManager.isSandboxMode()
  const { isEarnAccountInitiated, isSGDAccountInitiated, earnAccount, SGDAccount } = useSelector(
    (state: RootState) => state.landingPage
  )
  const { fullName, email, bizId, kcId, countryCode, mobileNumber } = user
  const { fazzbiz_enable_clevertap: isClevertapEnabled } = useSelector(
    (state: RootState) => state.featureGate.featureGateIDs
  )
  const { isTermLoanInitialised } = useSelector((state: RootState) => state.termLoan)

  function handleWebViewNavigation(event: MessageEvent<NavigationCommand>) {
    if (
      event.data.instruction === 'route' &&
      event.data.origin === 'fazzbiz_app' &&
      event.data.path
    ) {
      history.push(event.data.path)
    }
  }

  useRollbarPerson(user)

  useEffect(() => {
    i18n.changeLanguage(Language.ENGLISH)
  }, [])

  useEffect(() => {
    const isMobileApp = getWebOrAppAgent === UserAgent.MOBILE_APP
    const handleMessageEvent = (event: MessageEvent) => handleWebViewNavigation(event)

    if (isMobileApp && window?.parent) {
      window.addEventListener('message', handleMessageEvent)
    }
    return () => {
      if (isMobileApp && window?.parent) {
        window.removeEventListener('message', handleMessageEvent)
      }
    }
  }, [getWebOrAppAgent, history])

  // to close the modal dialog whenever there is a page navigation or history change
  useEffect(() => {
    const unlisten = history.listen(resetModal)

    return unlisten
  }, [history])

  const { isFlipperLoading, isFlipperInitiated } = useSelector((state: RootState) => state.flippers)

  useEffect(() => {
    if (!isInitiated) dispatch(init(AppType.SINGAPORE))

    dispatch(fetchWallets(AppType.SINGAPORE))
    dispatch(getEmployers())
    if (!isTermLoanInitialised) {
      dispatch(getTermLoanUserDetails({ initialLoad: !isTermLoanInitialised })) // required, to know if user has a loan profile
    }
    // Dependency necessary to wait for api calls from landing page
  }, [
    isInitiated,
    isEarnAccountInitiated,
    isSGDAccountInitiated,
    isFlipperInitiated,
    bizId,
    kcId,
    isClevertapEnabled,
    isTermLoanInitialised,
  ])

  const { data: maintenanceInfoData } = useGetMaintenanceInfo('sg-maintenance-page')
  const isMaintenanceModeActive =
    maintenanceInfoData?.data?.data?.attributes?.bannerStatus === 'active'

  if (isMaintenanceModeActive) {
    return <MaintenancePage endDate={maintenanceInfoData?.data?.data?.attributes?.expiryDate} />
  }

  if (!isInitiated || isFlipperLoading) {
    return <PageLoader />
  }

  if (isProduction && clarity.hasStarted()) {
    clarity.identify(user.kcId || user.email, {
      name: user.fullName,
      email: user.email,
      bizId: user.bizId,
    })
  }

  return (
    <Root id="biz-regional-root">
      <RouteHooks appType={AppType.SINGAPORE} />

      <Suspense fallback={<LoadingPage />}>
        <Switch>
          {getCustomLayoutRoutes().map((route) => (
            <Route path={route.path as string}>
              <Switch>
                <PrivateRoute
                  key={route.path}
                  routes={route}
                  {...route}
                  appType={AppType.SINGAPORE}
                />
              </Switch>
            </Route>
          ))}

          <Route path="/">
            <DashboardLayout
              sidebar={<Sidebar />}
              header={
                <UserInfoBar
                  userInfo={user}
                  isSandbox={isSandbox}
                  // notifications={notifications}
                  // ToDo: Add Notifications again once feature gets developed for realtime data
                  role={role}
                  appType={AppType.SINGAPORE}
                />
              }
              footer={<Footer appType={AppType.SINGAPORE} />}
            >
              <MainContainer>
                <ErrorBoundary
                  key={location.pathname}
                  errorMessage="Error in React render"
                  level={LEVEL_ERROR}
                  fallbackUI={() => <ErrorPage />}
                >
                  <Switch>
                    {routes.map((route) => (
                      <PrivateRoute
                        key={route.path}
                        routes={route}
                        {...route}
                        appType={AppType.SINGAPORE}
                      />
                    ))}
                  </Switch>
                </ErrorBoundary>
              </MainContainer>
            </DashboardLayout>
          </Route>
        </Switch>
      </Suspense>
    </Root>
  )
}

export function LoadingPage() {
  return <PageLoader />
}

const Root = styled.div`
  z-index: 0;
  transform: translate3d(0, 0, 0); //for safari, z-index issues with modal
`

const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 0;
  overflow: hidden;

  ${DESKTOP_BREAKPOINT} {
    padding-top: 0;
  }
`
