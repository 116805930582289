import { ErrorBoundary, LEVEL_ERROR, useRollbarPerson } from '@rollbar/react'
import { Suspense, useEffect, useState } from 'react'
import { clarity } from 'react-microsoft-clarity'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled/macro'
import { Card, DashboardLayout, DESKTOP_BREAKPOINT, fdsTheme, useModal } from '@fazz/design-system'
import { Route, Switch, useLocation, useHistory } from '~/hooks/useReactRouter'
import routes from '~/biz/routes/config'
import { getEmployers, init } from '~/biz/store/route/actions'
import type { RootState } from '~/biz/store/types'
import { getCustomLayoutRoutes } from '~/bizRegional/routes/config'
import { fetchWallets } from '~/rootStore/wallets/actions'
import { getIdTncStatus } from '~/api/initialisation/initialisation.api'
import {
  InitialisationApiQueryKey,
  useGetMaintenanceInfo,
} from '~/api/initialisation/initialisation.hooks'
import type { TncStatusReponseType } from '~/api/initialisation/initialisation.types'
import { getProductTourStatus, IdOnboardingFlowQueryKey } from '~/api/onboardingFlow/onboarding.api'
import type { ProductTourStatusType } from '~/api/onboardingFlow/onboarding.types'
import { AppType } from '~/types'
import ErrorPage from '~/components/ErrorPage'
import Footer from '~/components/Footer'
import MaintenancePage from '~/components/MaintenancePage'
import PageLoader from '~/components/PageLoader'
import { PrivateRoute, RouteHooks } from '~/components/Route'
import Sidebar from '~/components/Sidebar/Sidebar'
import UserInfoBar from '~/components/UserInfoBar'
import { ConfigManager, isProduction } from '~/managers/ConfigManager'
import { ModeManager } from '~/managers/ModeManager'
import { VerificationStatus } from '~/constants/verificationStatus'
import ClevertapUtils from '~/utils/ClevertapUtils'
import ProductTour, { stepsOption } from './components/ProductTour'
import ProductTourStartModal from './components/ProductTour/ProductTourStartModal'
import TncModal from './components/TnCModal/Modal'
import hasIDRCashAccount from './helpers/hasIDRCashAccount'
import {
  setProductTourId,
  setStepIndex,
  toogleProductTourActive,
} from './store/onboardingFlow/actions'
import { getFeatureMatrixFactors } from './store/route/selectors'
import { RouteActions } from './store/route/types'

const { spacing, colors, radius } = fdsTheme

export default function IDRoot() {
  const dispatch = useDispatch()

  const location = useLocation()
  const { setModal } = useModal()
  const history = useHistory()
  const { isInitiated, user, isClevertapInitiated } = useSelector((state: RootState) => state.route)
  const { wallets, isWalletInitiated } = useSelector((state: RootState) => state.wallets)
  const { businessVerificationStatus } = useSelector(
    (state: RootState) => state.route.featureMatrix
  )
  const { isFlipperLoading, isFlipperInitiated } = useSelector((state: RootState) => state.flippers)
  const { role, isAccountManagerVerified, isBizVerified } = useSelector(getFeatureMatrixFactors)
  const isSandbox = ModeManager.isSandboxMode()
  const { fullName, email, mobileNumber, bizId, kcId, countryCode } = user
  const { fazzbiz_enable_clevertap: isClevertapEnabled } = useSelector(
    (state: RootState) => state.featureGate.featureGateIDs
  )

  const [openProductTourDialog, setOpenProductTourDialog] = useState(false)
  const [isTncChecked, setIsTncChecked] = useState(false)

  const excludedKycStatus = [VerificationStatus.Initial, VerificationStatus.Pending]

  useRollbarPerson(user)
  useQuery(
    InitialisationApiQueryKey.TncStatus,
    async () => {
      const response = await getIdTncStatus()
      return response.data
    },
    {
      onSuccess: (response: TncStatusReponseType) => {
        if (
          !response.data?.attributes.hasAgreed &&
          !excludedKycStatus.includes(businessVerificationStatus)
        ) {
          setModal(<TncModal setIsTncChecked={setIsTncChecked} />)
        } else {
          setIsTncChecked(true)
        }
      },
      enabled: isInitiated,
    }
  )

  const includedProductTourStatus: ProductTourStatusType[] = ['starting', 'on_going']
  const includedKybStatus = [VerificationStatus.Initial, VerificationStatus.Pending]

  useQuery(IdOnboardingFlowQueryKey.IdProductTourGetStatus, getProductTourStatus, {
    onSuccess: (response) => {
      const { id, attributes } = response.data.data
      const step = attributes.productTourStep - 2
      if (includedProductTourStatus.includes(attributes.productTourStatus)) {
        dispatch(setProductTourId(id))
        if (attributes.productTourStatus === 'starting' && step < 0) {
          setOpenProductTourDialog(true)
        }
        if (step >= 0 && attributes.productTourStatus !== 'done') {
          dispatch(setStepIndex(step))
          dispatch(toogleProductTourActive(true))
          history.push(stepsOption[step].path)
        }
      }
    },
    enabled: isInitiated && includedKybStatus.includes(businessVerificationStatus) && isTncChecked,
  })

  useEffect(() => {
    if (!isInitiated) {
      dispatch(init(AppType.INDONESIA))
    }

    dispatch(fetchWallets(AppType.INDONESIA))
    dispatch(getEmployers())
    // Dependency necessary to wait for api calls from fetching wallet
  }, [isInitiated, isWalletInitiated, bizId, kcId, isFlipperInitiated, isClevertapEnabled])

  const { data: maintenanceInfoData } = useGetMaintenanceInfo('id-maintenance-page')
  const isMaintenanceModeActive =
    maintenanceInfoData?.data?.data?.attributes?.bannerStatus === 'active'

  if (isMaintenanceModeActive) {
    return <MaintenancePage endDate={maintenanceInfoData?.data?.data?.attributes?.expiryDate} />
  }

  if (!isInitiated || isFlipperLoading) {
    return <PageLoader />
  }

  if (isProduction && clarity.hasStarted()) {
    clarity.identify(user.kcId || user.email, {
      name: user.fullName,
      email: user.email,
      bizId: user.bizId,
    })
  }

  return (
    <Root id="biz-root">
      {includedKybStatus.includes(businessVerificationStatus) && <ProductTour />}
      <ProductTourStartModal open={openProductTourDialog} setOpen={setOpenProductTourDialog} />

      <RouteHooks appType={AppType.INDONESIA} />
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          {getCustomLayoutRoutes().map((route) => (
            <Route path={route.path as string}>
              <Switch>
                <PrivateRoute
                  key={route.path}
                  routes={route}
                  {...route}
                  appType={AppType.INDONESIA}
                />
              </Switch>
            </Route>
          ))}

          <Route path="/">
            <DashboardLayout
              sidebar={<Sidebar />}
              header={
                <UserInfoBar
                  userInfo={user}
                  isSandbox={isSandbox}
                  // notifications={notifications}
                  // ToDo: Add Notifications again once feature gets developed for realtime data
                  role={role}
                  appType={AppType.INDONESIA}
                />
              }
              footer={<Footer appType={AppType.INDONESIA} />}
            >
              <MainContainer>
                <ErrorBoundary
                  key={location.pathname}
                  errorMessage="Error in React render"
                  level={LEVEL_ERROR}
                  fallbackUI={() => <ErrorPage />}
                >
                  <Switch>
                    {routes.map((route) => (
                      <PrivateRoute
                        key={route.path}
                        routes={route}
                        {...route}
                        appType={AppType.INDONESIA}
                      />
                    ))}
                  </Switch>
                </ErrorBoundary>
              </MainContainer>
            </DashboardLayout>
          </Route>
        </Switch>
      </Suspense>
    </Root>
  )
}

function LoadingPage() {
  return (
    <Page>
      <Card style={{ padding: spacing.xl }}>
        <Skeleton style={{ width: '40%' }} />
        <Skeleton />
        <Skeleton />
      </Card>
    </Page>
  )
}

const Page = styled.div`
  padding: ${spacing.md};
  background: none;

  ${DESKTOP_BREAKPOINT} {
    background-color: ${colors.backgroundSecondaryNeutral};
    padding: ${spacing.lg};
  }
`

const Root = styled.div`
  z-index: 0;
  transform: translate3d(0, 0, 0); //for safari, z-index issues with modal
`

const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 0;
  overflow: hidden;

  ${DESKTOP_BREAKPOINT} {
    padding-top: 0;
  }
`

const Skeleton = styled.div`
  float: left;
  width: 100%;
  height: 16px;

  margin: ${spacing.xs};
  border-radius: ${radius.xs};
  background-size: 100vw;
  background-image: linear-gradient(90deg, #e8e8e8 0%, #f6f7f9 50%, #e8e8e8 100%);
  animation: shine-lines 1s infinite linear;

  @keyframes shine-lines {
    0% {
      background-position: -100px;
    }
    40%,
    100% {
      background-position: 140px;
    }
  }
`
